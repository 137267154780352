import api from '../../utils/api'

// 授权
export async function get_question (payload) {
  return api.get(`/api/v1/mp/orders/forbidden_detail`, {params: payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function get_other_question (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data:{
  //         patient_name: '李明',
  //         patient_gender_label: '男',
  //         patient_age: new Date() - 32*365*24*3600*1000,
  //         apply_time: new Date(),
  //         forbidden_list: [
  //           {
  //             title: '你以前是否发生脑血管意外,如 脑梗 中风 脑出血等',
  //             id: 1,
  //           },
  //           {
  //             title: '你以前是否在使用胰岛素治疗的糖尿病',
  //             id: 2,
  //           },
  //           {
  //             title: '你以前是否发生脑血管意外,如 脑梗 中风 脑出血等',
  //             id: 3,
  //           }
  //         ],
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/orders/anesthesia_detail`, {params: payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function post_question (payload) {
  return api.post(`/api/v1/mp/orders/anesthesia_detail?order_id=${payload.order_id}`, payload.arr)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function pay_order (payload) {
  return api.get(`/api/v1/mp/orders/pay_url`, {params: {order_id: payload}})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}







