<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="order_head">
        <div class="title_box">
          <div class="order_title">{{anesthesia_estimate?'麻醉':'禁忌症'}}评估</div>
        </div>
        <p>请如实回答下述问题</p>
      </div>
      <div class="question_box" v-if="data">
        <div class="question_box_inner">
          <div class="question_title">{{anesthesia_estimate?'麻醉':'禁忌症'}}问卷</div>
          <div class="question_info">
            <div class="info_text" v-if="data.patient_name">
              患者姓名: {{ data.patient_name }}
            </div>
            <div class="info_text" v-if="data.patient_gender_label">
              性别: {{ data.patient_gender_label }}
            </div>
            <div class="info_text" v-if="data.patient_age">
              年龄: {{ data.patient_age }}岁
            </div>
          </div>
          <div class="info_text" v-if="data.apply_time">
            申请时间:
            {{ format_date(data.apply_time, "yyyy年MM月dd日 HH:mm:ss") }}
          </div>
          <div
            class="questions"
            v-for="(item, index) in data.question_list"
            :key="index"
          >
            <div class="question_sub_title">
              <span>*</span>{{ index + 1 }}. {{ item.title }}
            </div>
            <div class="question_btn_box">
              <div
                :class="['question_btn', { active: item.selected === true }]"
                @click="answer(item, true)"
              >
                是
              </div>
              <div
                :class="['question_btn', { active: item.selected === false }]"
                @click="answer(item, false)"
              >
                否
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="submit_box" v-if="data.question_list">
        <van-button
          class="submit_btn"
          :disabled="answered !== data.question_list.length"
          @click="submit_fn"
          >{{ anesthesia_estimate ? "提交申请" : "提交" }}</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_question,
  pay_order,
  get_other_question,
  post_question,
} from "./service";
import { Dialog } from "vant";
import { format_date } from "../../utils/format";

export default {
  data() {
    return {
      data: {},
      loading: false,
      id: this.$route.query.id,
      anesthesia_estimate: this.$route.query.anesthesia_estimate === "true",
      answered: 0,
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.get_list();
  },
  destroyed() {},
  methods: {
    format_date,
    async get_list() {
      this.loading = true;
      try {
        const { data } = this.anesthesia_estimate
          ? await get_other_question({ order_id: this.id })
          : await get_question({ order_id: this.id });
        this.data = data;
        this.data.question_list = data.anesthesia_list || data.forbidden_list;
        this.data.question_list.forEach(item=>{
          item.selected = undefined
        })
      } finally {
        this.loading = false;
      }
    },
    submit_fn() {
      if (this.anesthesia_estimate) {
        let arr = [];
        this.data.question_list.forEach((item) => {
          arr.push({
            title: item.title,
            awnser: item.selected ? "是" : "否",
          });
        });
        this.post_answer({
          order_id: this.id,
          arr: {anesthesias: [...arr]},
        });
      } else {
        this.confirm_click();
      }
    },
    async post_answer(params) {
      this.loading = true;
      try {
        await post_question(params);
        this.$router.push({
          path: "/submit-success",
        });
      } finally {
        this.loading = false;
      }
    },
    async confirm_click() {
      this.loading = true;
      try {
        const { data } = await pay_order(this.id);
        window.open(data.content.payUrl, "_blank");
      } finally {
        this.loading = false;
      }
    },
    answer(item, bolean) {
      if (!this.anesthesia_estimate) {
        if (item.selected !== undefined) {
          return;
        }
        item.selected = bolean;
        if (bolean === false) {
          this.answered++;
        } else {
          Dialog.alert({
            title: "提示",
            message: "抱歉您存在检查禁忌症风险，请去医院门诊咨询预约",
          }).then(() => {
            this.$router.go(-1);
          });
        }
      } else {
        if(item.selected === undefined) {
          this.answered++
        }
        item.selected = bolean;
        this.$forceUpdate()
      }
    },
  },
};
</script>

<style scoped >
.order_head {
  padding: 31px 17px 27px;
  background: url("../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
  color: #ffffff;
  font-size: 12px;
  line-height: 17px;
}

.title_box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
}

.order_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  color: #ffffff;
}

.g_main_content {
  display: flex;
  flex-direction: column;
}

.question_box {
  background: #f7f7f7;
  flex: 1;
  overflow-y: auto;
}

.question_box_inner {
  padding: 23px 16px 40px;
  background: #ffffff;
  color: #565656;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
}

.question_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.question_info {
  padding: 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info_text {
  font-size: 14px;
  line-height: 16px;
}

.questions {
  padding-top: 25px;
}

.question_sub_title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 19px;

  & span {
    color: #ff6600;
    margin-right: 3px;
  }
}

.question_btn_box {
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.question_btn {
  width: 67px;
  height: 32px;
  background: #eaeaea;
  border-radius: 21px;
  font-size: 14px;
  font-family: Inter-Regular;
  line-height: 32px;
  text-align: center;
  margin-right: 8px;

  &.active {
    color: #fff;
    background: #0088ff;
  }
}

.submit_box {
  height: 76px;
  padding: 8px 18px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
}

.submit_btn {
  height: 40px;
  background: #0088ff;
  border-radius: 9px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
  width: 100%;
}
</style>